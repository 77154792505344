import moment from 'moment/moment';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';

export async function calculateProductionPlanning(
  locations,
  startHour,
  workHours,
  startDate,
  assemblyTasks,
  elements,
  elementWorkloads,
  productionLane,
  productionTask,
  assemblyHallId,
  elementAssemblyTasks,
  vacationDays,
  alignedPhase
) {
  let tasks = [];

  let alignedTasks = [];
  if (alignedPhase) {
    const { tasks } = await queryTasksV2(alignedPhase.license, [
      {
        column: 'parent',
        operator: '=',
        values: [alignedPhase.id],
      },
      {
        column: 'type',
        operator: '=',
        values: ['dhme-element-assembly'],
      },
      {
        column: 'project',
        operator: '=',
        values: [alignedPhase.task_project.id],
      },
    ]);
    alignedTasks = tasks;
  }

  let locationsByLane = locations.filter(
    (l) => l.assembly_lane.toString() === productionLane.toString()
  );

  locationsByLane.forEach((l) => {
    if (alignedPhase) {
      let locationEndDates = alignedTasks
        .filter((t) => t.task_type.custom_3 === l.id)
        .map((t) => moment(t.planned_end));
      if (locationEndDates.length > 0) {
        l.current = moment.max(locationEndDates).clone();
      } else {
        l.current = moment(alignedPhase.planned_start).set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }
      if (l.current.isSame(l.dayEnd)) {
        l.current = addBusinessDays(l.dayEnd, 1, vacationDays).clone().set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        l.dayEnd = l.current.clone().set({
          hour: startHour + workHours,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }
    } else {
      l.dayEnd = moment(startDate)
        .clone()
        .set({
          hour: startHour + workHours,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      l.current = moment(startDate).clone().set({
        hour: startHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
  });

  assemblyTasks.forEach((t) => {
    let moduleElements = elements.filter((e) => e.module_id === t.sbscode.code);
    moduleElements.forEach((e) => {
      let elementPlanned = false;
      let elementWorkloadInMinutes = elementWorkloads[e.element_type];

      let task = {
        title: `Produce (${e.element_type})`,
        parent: productionTask.id,
        project: productionTask.task_project.id,
        sbscode: e.element_id,
        options: {
          type: 'dhme-element-assembly',
          custom_1: t.sbscode.code,
          custom_2: assemblyHallId,
        },
      };

      let plannedTask = elementAssemblyTasks.find(
        (t) => t?.sbscode?.code === task?.sbscode
      );

      // set id to update
      if (plannedTask) {
        task.id = plannedTask.id;
      }

      while (!elementPlanned) {
        let sortedLocations = locationsByLane.sort((a, b) =>
          moment(a.current).diff(moment(b.current))
        );

        let location = locationsByLane.find(
          (l) => l.id === sortedLocations[0].id
        );

        task.options.custom_3 = location.id;

        let startDate = moment(location.current).clone();
        let endDate = startDate
          .clone()
          .add(elementWorkloadInMinutes, 'minutes');

        while (endDate.isAfter(location.dayEnd)) {
          let diff = endDate.diff(location.dayEnd, 'minutes');

          endDate = addBusinessDays(endDate, 1, vacationDays)
            .clone()
            .set({
              hour: startHour,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .add(diff, 'minutes');

          location.dayEnd = endDate.clone().set({
            hour: startHour + workHours,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
        }

        location.current = endDate.clone();

        task.planned_start = startDate.format('YYYY-MM-DD HH:mm:ss');
        task.planned_end = endDate.format('YYYY-MM-DD HH:mm:ss');

        if (endDate.isSame(location.dayEnd)) {
          location.current = addBusinessDays(endDate, 1, vacationDays)
            .clone()
            .set({
              hour: startHour,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
          location.dayEnd = location.current.clone().set({
            hour: startHour + workHours,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
        }

        elementPlanned = true;
      }
      tasks.push(task);
    });
  });

  return tasks;
}

export function addBusinessDays(originalDate, numDaysToAdd, vacationDays) {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;

  const newDate = moment(originalDate).clone();

  while (daysRemaining > 0) {
    newDate.add(1, 'days');
    if (
      newDate.day() !== Sunday &&
      newDate.day() !== Saturday &&
      !vacationDays.some((d) =>
        moment(d).isSame(newDate.clone().format('YYYY-MM-DD'), 'date')
      )
    ) {
      daysRemaining--;
    }
  }

  return newDate;
}
